import React, { Component } from 'react';
import testimonial_holder from '../data/testimonials.json';
import '../components/css/google-fonts.css';
import fonts from '../components/fonts.json';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";

class TestimonialGallery extends Component {
    constructor(props){
        super(props);
        this.testimonials = testimonial_holder.testimonials;
        this.fontIndex = [];
        for(let i = 0; i < this.testimonials.length; i++){
            this.fontIndex.push(Math.floor(Math.random()*fonts.length))
        }

        this.state =  {
          index: 0,
          testimonial: this.testimonials[0],
          font: this.fontIndex[0],
          showPrevious: false,
          showNext: true
        }
        
        this.changeTestimonial = this.changeTestimonial.bind(this);
        this.incrementTestimonial = this.incrementTestimonial.bind(this);
        this.decrementTestimonial = this.decrementTestimonial.bind(this);
      }
    
      incrementTestimonial(e){
        e.preventDefault();
        this.changeTestimonial(1);
      }
    
      decrementTestimonial(e){
        e.preventDefault();
        this.changeTestimonial(-1)
      }
    
    changeTestimonial(increment){
        let next = this.state.index + increment;
        if(next < 0)
            next = 0;
        if(next >= this.testimonials.length)
            next = this.testimonials.length - 1;
        
        this.setState({
            index: next,
            testimonial: this.testimonials[next],
            font: this.fontIndex[next],
            showPrevious: next > 0,
            showNext: next < this.testimonials.length-1
        });
    }
    render(){
        return(
            <div className="container clearfix">
                <div className="row col-mb-50 button-padding">
                    <div className="col-4 justify-content-start">
                        <button name="prev-testimonial" className="btn btn-secondary" style={{display: this.state.showPrevious ? "block" : "none"}} onClick={this.decrementTestimonial}><FaArrowCircleLeft /> Previous</button>
                    </div>
                    <div className="col-4 offset-4 justify-content-end">
                        <button name="next-testimonial" className="btn btn-secondary" style={{float: "right", display: this.state.showNext ? "block" : "none"}} onClick={this.incrementTestimonial}>Next <FaArrowCircleRight /></button>
                    </div>
                </div>
                <div className="row col-mb-50">
                    <div className="col-12">
                        <div style={{fontFamily: `${fonts[this.state.font]}`, fontSize: "30px"}}>
                        <ReactMarkdown plugins={[gfm]} children={this.state.testimonial} />
                        </div>
                    </div>
                </div>
                <div className="row col-mb-50 button-padding">
                    <div className="col-4">
                        <button name="prev-testimonial" className="btn btn-secondary" style={{display: this.state.showPrevious ? "block" : "none"}} onClick={this.decrementTestimonial}><FaArrowCircleLeft /> Previous</button>
                    </div>
                    <div className="col-4">
                    <p className="testimonial-page"> Testimonial {this.state.index + 1} of {this.testimonials.length}</p>
                    </div>
                    <div className="col-4">
                        <button name="next-testimonial" className="btn btn-secondary" style={{float: "right", display: this.state.showNext ? "block" : "none"}} onClick={this.incrementTestimonial}>Next <FaArrowCircleRight /></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default TestimonialGallery